import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { NavLink } from "react-router-dom";
import PinChangeIcon from "../assets/images/birthdayCake.svg";
import back_white from "../assets/images/back_white.svg";
import LovedOnePhoneName from "components/LovedPhoneName";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import { toast } from "react-toastify";
import { getWebUrlDetails, updateWebUrlDetails } from "api/api";
import CustomModal from "components/Modal/modal";
import PinChange from "./PinChange";

const CheckboxOption = ({ children, isSelected, ...props }) => (
  <components.Option {...props}>
    <input type="checkbox" checked={isSelected} />
    {children}
  </components.Option>
);

function WebUrl() {
  const lovedone = useSelectedLovedOne();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [data, setData] = useState([]);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [isOpenModal, setOpenModal] = useState(false);

  const handleModal = (status) => () => {
    setOpenModal(status);
  };
  const handlepin = () => {
    setOpenModal(true);
  };
  const handleUpdate = () => {
    const selectedMenuIds = selectedOptions.map((option) => option.menuId);
    const requestBody = {
      external_menu_id: selectedMenuIds,
      loved_one_id: lovedone.id,
    };
    updateWebUrlDetails(requestBody).then((res) => {
      toast(`${res?.data?.message}`, { type: "success" });
      setUpdateTrigger((prevTrigger) => prevTrigger + 1);
    });
  };

  useEffect(() => {
    if (lovedone && lovedone.id) {
      console.log("lovedone->>>>>>>>>>>", lovedone)
      getWebUrlDetails(lovedone.id).then((res) => {
        setData(res.data.data.external_menu);
        const modifiedSelectedOptions = res.data.data.lo_external_menu.map(
          (option) => {
            return {
              label: option.title,
              value: option.title + " - " + option.url,
              menuId: option.menu_id,
            };
          }
        );
        setSelectedOptions(modifiedSelectedOptions);
      });
    }
  }, [lovedone && lovedone.id && updateTrigger]);

  const categoryOptions = {};

  data.forEach((item) => {
    if (!categoryOptions[item.category_title]) {
      categoryOptions[item.category_title] = [];
    }
    if (!categoryOptions[item.category_title].includes(item.menu_title)) {
      categoryOptions[item.category_title].push({
        label: item.menu_title,
        value: item.menu_title,
        menuId: item.menu_id, // Include the menu_id here
      });
    }
  });

  const options = Object.keys(categoryOptions).map((category) => ({
    label: category,
    options: categoryOptions[category],
  }));
  return (
    <div className="mainPage">
      <div className="Homeheaderbg ">
        <div className="">
          <NavLink to="/MenuScreen">
            <img src={back_white} alt="" title="" />
          </NavLink>
        </div>
        <div className="customer-info">
          <div className="col-md-12">
            <h3 className="fs32">Add</h3>
            <h1>Web URL</h1>
          </div>
        </div>
      </div>
      <LovedOnePhoneName loved_one={lovedone} className="mtminus" />

      <div className="contentBody contentboxgray  contentbodymedia">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="textstyle">
              <p className="pt-4">
                {lovedone?.first_name} never needs to worry they will miss an
                important Update. You can remind {lovedone?.first_name} about
                Important Web Url. Your chosen Top 5 Web Url will be Shown on
                their <b>Big Purple Phone</b> on the day you enter.
              </p>
            </div>
            <h5 className="mt-0" style={{ fontFamily: "helveticaneuemedium" }}>
              Please Select Upto 5 URL's
              <span style={{ marginLeft: "10%" }} onClick={handlepin}>
                <img src={PinChangeIcon} alt="" />
              </span>
            </h5>
          </div>
        </div>
        <div className="selecturl-opt">
          <Select
            className="selectopt"
            value={selectedOptions}
            options={options}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isOptionSelected={(option) =>
              selectedOptions.some(
                (selectedOption) => selectedOption.value === option.value
              )
            }
            components={{
              Option: (optionProps) => (
                <CheckboxOption
                  {...optionProps}
                  isSelected={selectedOptions.some(
                    (selectedOption) =>
                      selectedOption.value === optionProps.data.value
                  )}
                />
              ),
            }}
            onChange={(selectedOption) => {
              if (selectedOption.length > 5) {
                return toast(
                  "Oops! You've reached the maximum limit of 5 web URL's",
                  {
                    type: "error",
                  }
                );
              } else {
                setSelectedOptions(selectedOption);
              }
            }}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary w-100 mt-3"
          onClick={handleUpdate}
        >
          Update Selected URL's
        </button>
      </div>
      <CustomModal
        toggleModal={handleModal}
        isOpen={isOpenModal}
        title={"Set Your PIN"}
        description={<PinChange />}
        prompt={{
          title: "Save",
          handle: () => {
            alert(`Hi Your Pin is ${localStorage.getItem("pin")}`);
          },
        }}
        close={true}
      />
    </div>
  );
}

export default WebUrl;
