import { Provider, useDispatch, useSelector } from "react-redux";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import dayjs from "dayjs";

import SplashScreen from "./screens/SplashScreen";
import LoginScreen from "./screens/LoginScreen";
import ForgotPass from "./screens/ForgotPass";
import OtpVerify from "./screens/OtpVerify";
import PasswordUpdateSuccess from "./screens/PasswordUpdateSuccess";
import PFAResetPassword from "./screens/PFAResetPassword";
import PFAHelpSupport from "./screens/PFAHelpSupport";
import { getDeviceToken, onMessageListener } from "./utils/firebase";
import store from "./redux/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import NotificationComponent from "./components/NotificationComponent";

import { SetupRoutes } from "./Routes/SetupRoutes";
import Authenticate from "./screens/SetupWalkThrough/Authenticate";
import NotFound from "./screens/NotFound";
import { ErrorBoundary } from "react-error-boundary";
import { getUserProfile } from "api/api";
import LOHelpSupport from "screens/LOHelpSupport";
import Maintenance from "screens/Maintenance";
import packageJson from "../package.json";
import withClearCache from "./ClearCache";
import AuthRoutes from "Routes/AuthRoutes";
import ErrorFallback from "components/Error";
import PFAVideoCall from "screens/PFAVideoCall";
import ShortUrl from "screens/ShortUrl";
import Test from "screens/Test";
import AutoLogin from "screens/AutoLogin";
import { getProfile, getRole } from "utils/localStorage";
import { loadSettings, updateSettings, updateSettingsSlice } from "redux/settingsSlice";
import { handleSettingsUpdate } from "utils/UpdateSettingHandler";
import MaintenancePage from "screens/MaintenancePage";

const queryClient = new QueryClient();
const ClearCacheComponent = withClearCache(MainApp);

const Unauthorized = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}{" "}
    </ErrorBoundary>
  );
};
const getBuildDate = (epoch) => {
  const buildDate = dayjs(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};
export default function App() {
  return <ClearCacheComponent />;
}
function MainApp() {
  const [isTokenFound, setTokenFound] = useState(false);
  let role = getRole();
  let profile = getProfile();
  const dispatch = useDispatch();
  // const lovedOneId = profile?.lovedOneList?.[0]?.id;

  useEffect(() => {

    // if (lovedOneId) {
    //   dispatch(loadSettings(lovedOneId));
    // }

    if (window && window.Notification) {
      Promise.resolve(Notification.requestPermission())
        .then(function (permission) {
          getDeviceToken(setTokenFound);
        })
        .catch((error) => {
          console.log("error in notification permission :>> ", error);
        });
    }
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("auto_login_id")) {

  //   }
  //   // else if (localStorage.getItem("api_token")) {
  //   //   getUserProfile({ role: "pfa" })
  //   //     .then((res) => {
  //   //       localStorage.setItem("profile", JSON.stringify(res.data.data[0]));
  //   //     })
  //   //     .catch((error) => {
  //   //       localStorage.removeItem("profile");
  //   //       localStorage.removeItem("api_token");
  //   //       window.location.assign("/");
  //   //     });
  //   // }
  //   else {
  //     getUserProfile({ role: "pfa" })
  //       .then((res) => {
  //         localStorage.setItem("profile", JSON.stringify(res.data.data[0]));
  //       })
  //       .catch((error) => {
  //         localStorage.removeItem("profile");
  //         localStorage.removeItem("api_token");
  //         window.location.assign("/");
  //       });
  //   }
  // },
  //   [
  //     localStorage.getItem("api_token")
  //   ]
  // );

  useEffect(() => {
    if (localStorage.getItem("auto_login_id")) {

    }


    else if (localStorage.getItem("api_token")) {
      const shouldHideBackButton = profile.lovedOneList.length > 0 && (
        profile.lovedOneList[0].relation === 'Self' || profile.lovedOneList[0].lo_id
      );
      getUserProfile({ role: shouldHideBackButton ? "lo" : "pfa", id: profile?.id })
        .then((res) => {
          localStorage.setItem("profile", JSON.stringify(res.data.data[0]));
        })
        .catch((error) => {
          localStorage.removeItem("profile");
          localStorage.removeItem("api_token");
          window.location.assign("/");
        });
    }
  },
    [
      localStorage.getItem("api_token")
    ]
  );
  if (typeof window != "undefined" && window.document) {
    const p = new Promise((r) => {
      if (navigator.serviceWorker.controller) return r();
      navigator.serviceWorker.addEventListener("controllerchange", (e) => r());
    });
    navigator.serviceWorker.addEventListener("message", (event) => {
      console.log("event", event.data)
        ;
      if (event.data.type == "missedVideoCall") {
        // window.location.href = "/";
      }
      if (event.data.data.type === 'settingsUpdate') {
        console.log("event.data.type", event.data.data)
        dispatch(updateSettingsSlice({
          lovedOneId: event.data.data.loved_one_id,
          settings: { select_to_speak: event.data.data.select_to_speak, loved_one_id: event.data.data.loved_one_id },
        }));

        // handleSettingsUpdate(event.data);
      }
      // if (event.data.data.type === 'settingsUpdate') {
      //   dispatch(updateSettings({ lovedOneId, settings: event.data.data }));
      // }
      // Add handler here
    });
  }
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ToastContainer theme={"colored"} hideProgressBar autoClose={2000} />{" "}
        <Router>
          <NotificationComponent />
          <Routes>
            <Route path="/" element={<SplashScreen />} index />
            <Route path="/videoCall/:short_url" element={<ShortUrl />} />
            {AuthRoutes()}
            {/* Public screens */}
            {SetupRoutes()}
            <Route
              path="/authenticate/:shopify_order_id"
              element={<Authenticate />}
            />
            <Route
              path="/LoginScreen"
              element={
                <Unauthorized>
                  <LoginScreen />
                  {/* <MaintenancePage /> */}
                </Unauthorized>
              }
            />{" "}
            <Route
              path="/TestWeb"
              element={
                <Unauthorized>
                  <Test />
                </Unauthorized>
              }
            />{" "}
            <Route
              path="/LoginScreen/:string"
              element={
                <Unauthorized>
                  <LoginScreen />
                </Unauthorized>
              }
            />
            <Route path="/ForgotPass" element={<ForgotPass />} />
            <Route path="/AutoLogin" element={< AutoLogin />} />
            <Route path="/OtpVerify" element={<OtpVerify />} />
            <Route path="/PFAResetPassword" element={<PFAResetPassword />} />
            <Route path="/PFAHelpSupport" element={<PFAHelpSupport />} />
            <Route path="/LOHelpSupport" element={<LOHelpSupport />} />
            <Route
              path="/PasswordUpdateSuccess"
              element={<PasswordUpdateSuccess />}
            />
            <Route
              path="/PFAVideoCall/:role/:caller_id/:room_id"
              element={<PFAVideoCall />}
            />
            <Route path="/Maintenance" element={<Maintenance />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
}
