import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Spinner, Modal } from "react-bootstrap";
import back_white from "assets/images/back_white.svg";
import AutoSuggest from "../components/AutoSuggest";
import noContactImage from "../assets/images/no-contact1.svg";

import useSelectedLovedOne from "hooks/useSelectedLovedOne";

import {
  getThirdPartyList,
  editThirdPartyList,
  editAllThirdPartyList,
} from "api/thirdParty";
import { useErrorHandler } from "react-error-boundary";
import { speakText } from "utils/utils";
import loader from "assets/images/loader.gif"


export default function ThirdParty({ type = 1 }) {
  const lovedOne = useSelectedLovedOne();
  const [allItems, setAllItems] = useState(true);
  const [list, setList] = useState([]);
  const [showList, setShowList] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const handleError = useErrorHandler();

  useEffect(() => {
    setLoading(true);
    if (lovedOne && lovedOne.id) {
      getThirdPartyList(lovedOne.id, type)
        .then((result) => {
          console.log("result :>> ", result);
          setList(result.data.data);
          setShowList(result.data.data);
          setAllItems(result.data.allStatus);
        })
        .catch((error) => {
          setList([]);
          console.log(error);
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [lovedOne && lovedOne.id]);

  const filteredList = (updated_list) => {
    setShowList(updated_list);
  };
  const handleCheck = (item) => async (event) => {
    console.log("heyyyyyyyy", event.target.checked ? 1 : 0);
    if (lovedOne && lovedOne.id) {
      let data = {
        is_active: event.target.checked ? 1 : 0,
        item_id: item.id,
        loved_one_id: lovedOne.id,
      };
      editThirdPartyList(data)
        .then(async (res) => {
          let updated_list = [...list];
          let changedItemIndex = updated_list.findIndex((l) => l.id == item.id);
          updated_list[changedItemIndex].active = data.is_active;
          setList([...updated_list]);
          let allSame = 1;
          updated_list.map((item, key) => {
            // item.active != data.is_active ? allSame = 0 : "";
            if (item.active != data.is_active) {
              allSame = 0;
            }
          });
          if (allSame) {
            await handleAllCheck(event);
            console.log(event.target.value, "targeted:::::::::::");
            setAllItems(data.is_active);
          }
        })
        .catch((error) =>
          error.response.data.statusCode != 400 ? handleError(error) : ""
        );
    }
  };
  const handleAllCheck = async (event) => {
    console.log(lovedOne, "lovedone:::::::::::");
    if (lovedOne && lovedOne.id) {
      let data = {
        is_active: event.target.checked ? 1 : 0,
        type: type,
        loved_one_id: lovedOne.id,
      };
      editAllThirdPartyList(data)
        .then((res) => {
          let updated_list = [...list];
          updated_list.map((item, index) => {
            updated_list[index].active = event.target.checked;
          });
          setList([...updated_list]);
        })
        .catch((error) =>
          error.response.data.statusCode != 400 ? handleError(error) : ""
        );
    }
  };

  // if (isLoading) return <Spinner animation="border" variant="primary" />;
  if (isLoading) {
    return (

      <div className="loader-container">
        <img src={loader} alt="Loading..." className="custom-loader" />
      </div>
    )
  }
  return (
    <div>
      <div className="mainPage">
        <div className="Homeheaderbg text-white">
          <div className="d-flex align-self-center">
            <NavLink to="/MenuScreen">
              <img src={back_white} onClick={() => speakText('Back', true)} />
            </NavLink>

            <h2 className="fs20 mb-0 text-center mx-auto">
              Manage {type == 1 ? "Radio" : "Music"}
            </h2>
          </div>
        </div>
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>
        <div className="contentBody contentbodymedia manageapps">
          <div className="">
            <div className="justify-content-center">
              <AutoSuggest
                data={list}
                filteredList={filteredList}
                findKey="name"
                setIsSearched={setIsSearched}
              />
              {!isSearched ? (
                <div key={"all"} className="media radioCircleImages">
                  <div className="media-body">
                    <h5 className="mt-0 mb-0 text-black">{`All ${type == 1 ? "Radio" : "Music"
                      } Stations:`}</h5>
                  </div>
                  <div
                    className="form-check form-switch ms-auto"
                    style={{ right: 0 }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={"radio"}
                      checked={allItems ? true : false}
                      onChange={(event) => {
                        setAllItems(event.target.checked);
                        handleAllCheck(event);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"radio"}
                    ></label>
                  </div>

                </div>
              ) : (
                ""
              )}
              {showList.length ? (
                showList.map((radio, index) => (
                  <div key={index} className="media radioCircleImages">
                    <div className={type == 1 ? "musicCircle" : ""}>
                      <div className={type == 1 ? "musicCircleInner" : ""}>
                        <div className={type == 1 ? "musicImgInner" : ""}>
                          <img
                            className="align-self-center "
                            src={radio.favicon}
                            alt=""
                            title=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="media-body">
                      <h5 className="mt-0 mb-0">{radio.name}</h5>
                    </div>
                    <div className="form-check form-switch ms-auto">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={radio.id}
                        checked={radio.active ? true : false}
                        onChange={handleCheck(radio)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={radio.id}
                      ></label>
                    </div>
                  </div>
                ))
              ) : (
                <div className=" text-center nocontact">
                  <img
                    src={noContactImage}
                    style={{ width: "25%" }}
                    alt="no contact"
                  />
                  <h3 className="pt-4">No results found</h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
