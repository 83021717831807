import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import back_white from "../assets/images/back_white.svg";
import addNewContact from "../assets/images/addNewContact.svg";
import user_small from "../assets/images/user_small.svg";
import noContactImage from "../assets/images/no-contact1.svg";
import bigPurplePhoneLogo from "../assets/images/bigPurpleLogo-dark.svg";
import send from "../assets/images/send.svg";

import {
  searchLovedOneContactList,
  getSettings,
  updateSettings,
  resendInvite,
} from "../api/api";
import useSelectedLovedOne from "../hooks/useSelectedLovedOne";
import AutoSuggest from "../components/AutoSuggest";
import useLoginUser from "hooks/useLoginUser";
import { useErrorHandler } from "react-error-boundary";
import { speakText } from "utils/utils";
import loader from "assets/images/loader.gif"

export default function SearchContact() {
  const navigate = useNavigate();
  const lovedOne = useSelectedLovedOne();

  const [isLoading, setLoading] = useState(false);
  const [showList, setShowList] = useState("");
  const [list, setList] = useState([]);
  const [settings, setSettings] = useState({});
  const user = useLoginUser();

  const handleError = useErrorHandler();
  useEffect(() => {
    setLoading(true);
    if (lovedOne && lovedOne.id) {
      searchLovedOneContactList(lovedOne?.id ?? "")
        .then((data) => {
          // let contacts = data.filter((ct) => ct.id != user.profile.id);
          let contacts = data;
          console.log("contacts", contacts);
          setList(contacts);
          setShowList(contacts);
          getSettings(lovedOne?.id ?? "")
            .then((data) => {
              setLoading(false);
              setSettings(data);
            })
            .catch((error) => {
              return error.response.data.statusCode != 400
                ? handleError(error)
                : "";
            });
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    }
  }, [lovedOne && lovedOne.id]);

  const filteredList = (updated_list) => {
    console.log("updated_list", updated_list);
    setShowList(updated_list);
  };

  const onClickContact = (data) => {
    console.log("data->>>>>>>>>>>>>>>", data)
    navigate("/ContactEdit", { state: data });
  };
  const handelSettings = (value) => {
    setLoading(true);
    setSettings({ ...settings, show_recent_call: value });
    updateSettings({
      ...settings,
      lovedOneId: lovedOne?.id,
      show_recent_call: value,
    })
      .then((data) => {
        setLoading(false);
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };
  const onResendInvite = (item) => {
    let data = {
      lovedOneId: lovedOne?.id,
      id: item.id,
    };
    resendInvite(data)
      .then((data) => { })
      .catch((error) => {
        searchLovedOneContactList(lovedOne?.id ?? "")
          .then((data) => {
            // let contacts = data.filter((ct) => ct.id != user.profile.id);
            setList(data);
            setShowList(data);
            getSettings(lovedOne?.id ?? "")
              .then((data) => {
                setSettings(data);
              })
              .catch((error) => {
                return error.response.data.statusCode != 400
                  ? handleError(error)
                  : "";
              });
          })
          .catch((error) => {
            return error.response.data.statusCode != 400
              ? handleError(error)
              : "";
          });
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };

  console.log("showList", showList)

  return (
    <div>
      <div className="mainPage">
        <div className="Homeheaderbg">
          <div className="">
            <NavLink to="/MenuScreen">
              <img src={back_white} alt="" onClick={() => speakText('Back', true)} />
            </NavLink>
          </div>
          <div className="customer-info">
            <div className="col-md-10">
              <h1>Contacts</h1>
            </div>
          </div>

          <div className="customer-info">
            <div className="col-md-6"></div>
            <div className="col-md-6"></div>
          </div>
        </div>

        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>
        <div className="contentBody contentbodymedia mtminus ">
          <div className="contentBody mb-5">
            <div className="row justify-content-center">
              <NavLink to="/Add_ImportContacts" className="mt-0">
                <div className="media boxmediablue text-white mt-4 mLeft0">
                  <img
                    className="align-self-center me-3"
                    src={addNewContact}
                    alt="contact"
                  />
                  <div className="media-body">
                    <h5 className="mt-0">
                      <b>Add New Contact</b>
                    </h5>
                  </div>
                </div>
              </NavLink>

              <h6>
                <b>NEW! You can now re-send an invitation to a Contact</b>
              </h6>
              <AutoSuggest
                data={list}
                filteredList={filteredList}
                findKey={"name"}
              />

              {isLoading ? (
                // <Spinner animation="border" variant="primary" />
                <div className="loader-container">
                  <img src={loader} alt="Loading..." className="custom-loader" />
                </div>
              ) : showList.length ? (
                showList.map((item, i) => (
                  <div key={i} className="media boxmediawhite row">
                    <div
                      onClick={() => onClickContact(item)}
                      className="d-flex col-9 col-md-9 "
                    >
                      <div className="">
                        <img
                          className="align-self-center me-3"
                          src={item.photo ? `${item.photo}` : user_small}
                          alt=""
                        />
                      </div>
                      <div
                        className="media-body "
                        style={{ paddingTop: "15px" }}
                      >
                        <h5 className="mt-0 mb-0">{item.name}</h5>
                      </div>
                    </div>
                    {!item.verify ? (
                      <div
                        className="media-body col-3 col-md-3"
                        onClick={() => onResendInvite(item)}
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        <img src={send} />
                        <small className="resendBtn">Resend Invite</small>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))
              ) : (
                <div className=" text-center nocontact">
                  <img src={noContactImage} alt="no contact" />
                  <h3 className="pt-4">No Contacts Found</h3>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bigpurplebottom text-center bgWhite">
          <img src={bigPurplePhoneLogo} alt="footer" />
        </div>
      </div>
    </div>
  );
}
