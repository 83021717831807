import React, { useEffect, useState } from "react";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import user from "assets/images/user.svg";
import loginMail from "assets/images/loginMail.svg";
import mobile from "assets/images/mobile.svg";
import calendar from "assets/images/calendar.svg";
import downArrow from "assets/images/downarrow.svg";
import street from "assets/images/street_icons.svg";
import city from "assets/images/location_city.svg";
import dayjs from "dayjs";

function InputFieldImg({ icon }) {
  const imgSrc = () => {
    switch (icon) {
      case "name":
        return user;
      case "email":
        return loginMail;
      case "phone":
        return mobile;
      case "dob":
        return calendar;
      case "downArrow":
        return downArrow;
      case "street":
        return street;
      case "city":
        return city;
      default:
        return null;
    }
  };

  return icon ? <img src={imgSrc()} alt="" title="" /> : "";
}

export const textField = (
  formik,
  name,
  label = false,
  placeholder,
  icon = null,
  type = "text",
  maxLength = false,
  disabled = false,
  required = false
) => {
  const { errors, values, touched } = formik;

  return (
    <>
      {label ? (
        <label htmlFor="" className="form-label">
          {label} {required ? <span className="error">*</span> : ""}
        </label>
      ) : null}
      <div className={icon ? "inputIcon" : ""}>
        <input
          name={name}
          onChange={formik.handleChange}
          style={{ color: "black" }}
          type={type}
          className={`form-control ${icon ? "iconInputLeft" : ""}`}
          value={formik.values[name]}
          placeholder={`${placeholder} ${label ? "" : required ? "*" : ""}`}
          maxLength={maxLength ? maxLength : 50}
          max={type == "date" ? dayjs().format("YYYY-MM-DD") : ""}
          disabled={disabled}
        />

        {icon ? (
          <span className="icons">
            <InputFieldImg icon={icon} />
          </span>
        ) : (
          ""
        )}
      </div>

      {errors[name] && touched[name] ? (
        <div className="error">{errors[name]}</div>
      ) : null}
    </>
  );
};

// export const phoneField = (
//   formik,
//   name,
//   label = false,
//   placeholder,
//   icon = null,
//   type = "text",
//   disabled = false
// ) => {
//   const { errors, values, touched } = formik;
//   let value = formik.values[name];

//   console.log("formik :>> ", formik);

//   // +61 491 570 159
//   // 0491 570 159

//   let inputStartsWith = value ? value.charAt(0) : 0;
//   let input_length = inputStartsWith == 6 ? 11 : 10;
//   let first_split_length = inputStartsWith == 6 ? 2 : 4;
//   let second_split_length = inputStartsWith == 6 ? 5 : 7;
//   let third_split_length = inputStartsWith == 6 ? 8 : 10;
//   const input = value
//     ? value.replace(/\D/g, "").substring(0, input_length)
//     : ""; // First eleven or 10 digits of input only

//   // const handleKeyDown = (event) => {
//   //   let code = event.keyCode;
//   //   let v = value.replaceAll(" ", "");
//   //   if (code == 8) {
//   //     return true;
//   //   } else if (
//   //     ((code > 47 && code < 58) || (code > 95 && code < 108)) &&
//   //     v.length < input_length
//   //   ) {
//   //     return true;
//   //   } else {
//   //     event.preventDefault();
//   //   }
//   // };

//   let zip = input.substring(0, first_split_length);
//   let middle = input.substring(first_split_length, second_split_length);
//   let last = input.substring(second_split_length, third_split_length);
//   let end = input.substring(third_split_length, input_length);
//   if (input_length == 11) {
//     if (input.length > third_split_length - 1) {
//       value = `${zip} ${middle} ${last} ${end}`;
//     } else if (input.length > second_split_length) {
//       value = `${zip} ${middle} ${last}`;
//     } else if (input.length > first_split_length) {
//       value = `${zip} ${middle}`;
//     } else {
//       value = `${zip}`;
//     }
//   } else {
//     if (input.length > second_split_length - 1) {
//       value = `${zip} ${middle} ${last}`;
//     } else if (input.length > first_split_length) {
//       value = `${zip} ${middle}`;
//     } else if (input.length > 0) {
//       value = `${zip}`;
//     }
//   }

//   // formik.values[name] = value ? value.trim() : "";
//   return (
//     <>
//       {label ? (
//         <label htmlFor="" className="form-label">
//           {label}
//         </label>
//       ) : null}
//       <div className="inputIcon">
//         <input
//           name={name}
//           onChange={formik.handleChange}
//           style={{ color: "black" }}
//           type={type}
//           className="form-control iconInputLeft"
//           // value={formik.values[name]}
//           value={value}
//           placeholder={placeholder}
//           // onKeyDown={handleKeyDown}
//           // disabled={disabled}
//         />

//         <span className="icons">
//           <InputFieldImg icon={icon} />
//         </span>
//       </div>

//       {errors[name] && touched[name] ? (
//         <div className="error">{errors[name]}</div>
//       ) : null}
//     </>
//   );
// };

// const formatMobile = (value) => {
//   let inputStartsWith = value ? value.charAt(0) : 0;
//   let input_length = inputStartsWith == 6 ? 11 : 10;
//   let first_split_length = inputStartsWith == 6 ? 2 : 4;
//   let second_split_length = inputStartsWith == 6 ? 5 : 7;
//   let third_split_length = inputStartsWith == 6 ? 8 : 10;

//   const input = value
//     ? value.replace(/\D/g, "").substring(0, input_length)
//     : ""; // First eleven or 10 digits of input only

//   let zip = input.substring(0, first_split_length);
//   let middle = input.substring(first_split_length, second_split_length);
//   let last = input.substring(second_split_length, third_split_length);
//   let end = input.substring(third_split_length, input_length);

//   if (input_length == 11) {
//     if (input.length > third_split_length - 1) {
//       value = `${zip} ${middle} ${last} ${end}`;
//     } else if (input.length > second_split_length) {
//       value = `${zip} ${middle} ${last}`;
//     } else if (input.length > first_split_length) {
//       value = `${zip} ${middle}`;
//     } else {
//       value = `${zip}`;
//     }
//   } else {
//     if (input.length > second_split_length - 1) {
//       value = `${zip} ${middle} ${last}`;
//     } else if (input.length > first_split_length) {
//       value = `${zip} ${middle}`;
//     } else if (input.length > 0) {
//       value = `${zip}`;
//     }
//   }

//   return value ? value.trim() : "";
// };
export const PhoneComponent = ({
  formik,
  name,
  label = false,
  placeholder,
  icon = null,
  type = "text",
  disabled = false,
  required = false,
}) => {
  const { errors, values, touched } = formik;

  // const [value, setValue] = useState(formik.values[name]);

  console.log("formik :>> ", formik);
  let value = formik.values[name];

  const handleChange = (value) => {
    formik.setFieldValue(name, value);
  };

  // +61 491 570 159
  // 0491 570 159
  // +61491570159;
  // 0491570159;
  // const handleKeyDown = (event) => {
  //   let inputStartsWith = value ? value.charAt(0) : 0;
  //   let input_length = inputStartsWith == 6 ? 11 : 10;
  //   let code = event.keyCode;
  //   let v = value.replaceAll(" ", "");
  //   if (code == 8) {
  //     return true;
  //   } else if (
  //     ((code > 47 && code < 58) || (code > 95 && code < 108)) &&
  //     v.length < input_length
  //   ) {
  //     return true;
  //   } else {
  //     event.preventDefault();
  //   }
  // };
  console.log("errors[name] , touched[name] :>> ", errors[name], touched[name]);
  return (
    // <>
    //   {label ? (
    //     <label htmlFor="" className="form-label">
    //       {label}
    //     </label>
    //   ) : null}
    //   <div className="inputIcon">
    //     <input
    //       name={name}
    //       onChange={formik.handleChange}
    //       style={{ color: "black" }}
    //       type={type}
    //       className="form-control iconInputLeft"
    //       value={formatMobile(formik.values[name])}
    //       placeholder={placeholder}
    //       onKeyDown={handleKeyDown}
    //       disabled={disabled}
    //     />

    //     <span className="icons">
    //       <InputFieldImg icon={icon} />
    //     </span>
    //   </div>

    //   {errors[name] && touched[name] ? (
    //     <div className="error">{errors[name]}</div>
    //   ) : null}
    // </>
    <>
      {label ? (
        <label htmlFor="" className="form-label">
          {label} {required ? <span className="error">*</span> : ""}
        </label>
      ) : null}
      <PhoneInput
        country="AU"
        name={name}
        countryCallingCodeEditable={false}
        placeholder={`Enter phone number ${label ? "" : required ? "*" : ""}`}
        value={formik.values[name]}
        onChange={handleChange}
        defaultCountry="AU"
        className="form-control"
        disabled={disabled}
      />{" "}
      {errors[name] ? <div className="error">{errors[name]}</div> : null}
    </>
  );
};

export const selectField = (
  formik,
  name,
  label = false,
  placeholder,
  options,
  icon = null
) => {
  let selectOptions = options.map((o, i) => (
    <option value={o.value}>{o.name}</option>
  ));
  const { errors, values, touched } = formik;

  return (
    <>
      {label ? (
        <label htmlFor="" className="form-label">
          {label}
        </label>
      ) : null}
      <div className="inputIcon">
        <select
          name={name}
          onChange={formik.handleChange}
          style={{ color: "black" }}
          className="form-control form-select "
          value={formik.values[name]}
        >
          <option value="">{placeholder}</option>
          {selectOptions}
        </select>
        {icon ? (
          <span className="icons">
            <InputFieldImg icon={icon} />
          </span>
        ) : (
          ""
        )}
      </div>
      {errors[name] && touched[name] ? (
        <div className="error">{errors[name]}</div>
      ) : null}
    </>
  );
};
