import { useEffect, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import customer from "assets/images/customer.svg";
import settings from "assets/images/settings.svg";
import user_small from "assets/images/user_small.svg";
import listRightArrow from "assets/images/listRightArrow.svg";
import bigPurplePhoneLogo from "assets/images/bigPurpleLogo-dark.svg";
import noContactImage from "assets/images/no-contact1.svg";

import useLoginUser from "hooks/useLoginUser";
import { searchLovedOne } from "api/api";
import { changeLovedOne } from "redux/lovedOne";
import AutoSuggest from "components/AutoSuggest";
import { useErrorHandler } from "react-error-boundary";
import IphonePWAPopup from "components/IphonePWAPopup";
import CustomModal from "components/Modal/modal";
import { getPassword } from "utils/localStorage";
import loader from "assets/images/loader.gif"


export default function LovedOneList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { profile } = useLoginUser();
  const [isLoading, setLoading] = useState(false);
  const [showList, setShowList] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [list, setList] = useState([]);
  const handleError = useErrorHandler();
  const password = getPassword();
  console.log("password", password)

  const toggleModal = () => () => {
    // setIsModalOpen(status);
    // const data = { item: "change" }
    navigate("/ChangePassword", { state: { type: "create" } })
  };


  const onClickContact = (item) => {
    console.log("item->>>>ll", item)
    dispatch(changeLovedOne(item));
    navigate("/MenuScreen", { state: item });
    // navigate("/MenuScreenSecond", { state: item });
  };


  useEffect(() => {
    setLoading(true);
    searchLovedOne("")
      .then((data) => {
        setLoading(false);
        setList(data);
        setShowList(data);
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  }, []);

  useEffect(() => {
    if (password === "bigpurplephone@123") {
      setIsModalOpen(true);
    }
  }, [password]);

  const filteredList = (updated_list) => {
    setShowList(updated_list);
  };

  // profile.photo = ''

  console.log("profiledata", profile.photo.length)
  return (
    <>


      <CustomModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        // toggleModal={toggleModal(false, { type: 'create' })}
        title="Change Your Password"
        description="For security reasons, we recommend changing your password after your first login."
        prompt={{
          cancel: "Change Password",
          // title: "Yes, Remove",
          // handle: confirmDisableManage,
        }} />
      <IphonePWAPopup />
      <div className="footerbottom">
        <div className="mainPage ">
          <div className="Homeheaderbg">
            <div className="customer-info">
              <div className="col-md-6">
                <h1>Hello,</h1>
                <h3>{profile.name}</h3>
              </div>
              <div className="pe-3">
                <div className="imgspace">
                  <img
                    src={profile.photo.length ? `${profile.photo}` : customer}
                    className="imgbig"
                    alt={profile.name}
                  />
                  <NavLink to="/PFASetting">
                    <img src={settings} className="imgsmall" alt="" />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="contentboxgraynotfixedheader">
            <br />
          </div>
          <div className="contentBody contentbodymedia pb95">
            <div className="">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <h5>
                    If you have more than one Loved one in your network
                    they will appear below. Which account would you like to
                    administer today?
                  </h5>
                  <h5 className="fs36">
                    <b>Please Select</b>
                    <br />
                    Big<b>Purple</b>Phone
                  </h5>
                  <AutoSuggest data={list} filteredList={filteredList} />
                  {isLoading ? (
                    // <Spinner animation="border" variant="primary" />
                    <div className="loader-container">
                      <img src={loader} alt="Loading..." className="custom-loader" />
                    </div>
                  ) : showList.length ? (
                    showList.map((item, i) => (
                      <div
                        key={i}
                        className="media boxmediawhite"
                        onClick={() => {
                          onClickContact(item);
                        }}
                      >
                        <img
                          className="align-self-center me-2"
                          src={item.photo.length ? `${item.photo}` : user_small}
                          alt={item.first_name}
                        />
                        <div className="media-body">
                          <h5 className="mt-0 mb-0">{`${item.first_name} ${item.last_name}`}</h5>
                        </div>
                        {item.photoUnchecked ? (
                          <div className="imagesLabelNo mediaarrow">
                            {`${item.photoUnchecked} New Photo${item.photoUnchecked > 1 ? "s" : ""
                              }!`}
                          </div>
                        ) : (
                          ""
                        )}
                        <img
                          src={listRightArrow}
                          alt=""
                          className="mediaarrow"
                        />
                      </div>
                    ))
                  ) : (
                    <div className=" text-center nocontact">
                      <img
                        src={noContactImage}
                        style={{ width: "25%" }}
                        alt="no contact"
                      />
                      <h3 className="pt-4">No results found</h3>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bottomLogo">
            <img src={bigPurplePhoneLogo} alt="" />
            {/* <p className="coptrighttext">
            © Copyright Henderson & Associates t/as BigPurplePhone 2022
            www.bigpurplephone.com
          </p> */}
          </div>
        </div>
      </div>
    </>
  );
}
