import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import back_white from "assets/images/back_white.svg";
import customer from "assets/images/customer.svg";
import menu_sos from "assets/images/menu_sos.svg";
import menu_accessibility from "assets/images/menu_accessibility.svg";
import menu_bpp from "assets/images/icon_bpp.svg";
import menu_games from "assets/images/gameApp.svg";
import menu_news from "assets/images/newsApp.svg";
import menu_whatsup from "assets/images/WhatsApp.png"
import web_apps from "assets/images/web_apps.png";
import custom_browser from "assets/images/custom_browser.svg";
import menu_covid_check_in from "assets/images/covid-checkin.svg";
import menu_help from "assets/images/menu_help.png"
import manage_media from "assets/images/manage_media.png"
import internet from "assets/images/internet.png"
import bigPurplePhoneLogo from "assets/images/bigPurpleLogo-light.svg";
import editCircle from "assets/images/editCircle.png";
import Billing from "assets/images/billing.svg";
import Support from "assets/images/supportVideo.svg";
import Contacts from "assets/images/contact.svg";
import Birthday from "assets/images/birthdaysAnniversaries.svg";
import phone_media from "assets/images/phone-media.png"
import videocall from "assets/images/videocall.png"
import message_media from "assets/images/message_media.png"
import photos from "assets/images/photos.png"
import Reminders from "assets/images/reminders.svg";
import wifi from "assets/images/Wifi.png"
import bluetooth from "assets/images/bluetooth.png"
import camera_media from "assets/images/camera_media.png"
import weather_media from "assets/images/weather_media.png"
import radio_media from "assets/images/radio_media.png"
import music_media from "assets/images/music_media.png"
import sos_media from "assets/images/sos_media.png"
import vaccination_media from "assets/images/vaccination_media.png"
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import { getLOMenuEditable, editLovedOneMenu, updateSharePhotos } from "api/api";
import { useErrorHandler } from "react-error-boundary";
import { getSettings, updateSettings } from "../api/api";
import { getUncheckedPhotoCount } from "api/gallery";
import { getAutoLoginUserid, getPassword, getRole } from "utils/localStorage";
import find_my_phone from "assets/images/icon find-my-phone.png"
import CustomModal from "components/Modal/modal";
import useLoginUser from "hooks/useLoginUser";
import { speakText } from "utils/utils";
import { useDispatch } from "react-redux";
import { loadSettingsFromLocalStorage, updateSettingsSlice } from "redux/settingsSlice";
import loader from "assets/images/loader.gif"
import myProfileIcon from "assets/images/myProfileIcon.svg";
import default_image from "assets/images/default_image.png"




const ImageComponent = {
  menu_phone: phone_media,
  menu_video_call: videocall,
  menu_messages: message_media,
  menu_camera: camera_media,
  menu_photos: photos,
  menu_weather: weather_media,
  icon_bpp: menu_bpp,
  menu_radio: radio_media,
  menu_games: menu_games,
  menu_news: menu_news,
  sos: menu_sos,
  menu_accessibility: menu_accessibility,
  menu_whatsup: menu_whatsup,
  custom_browser: custom_browser,
  menu_music: music_media,
  menu_vaccination: vaccination_media,
  menu_covid_check_in: menu_covid_check_in,
  menu_help: menu_help,
  menu_sos: sos_media,
  internet: internet,
  web_apps: web_apps,
  icon_bpp: manage_media,
  Billing,
  Support,
  Contacts,
  Birthday,
  Reminders,
  menu_wifi: wifi,
  menu_bluetooth: bluetooth,
  find_my_phone
};

export default function MenuScreen() {

  const lovedOne = useSelectedLovedOne();

  console.log("lovedonephoto", lovedOne.photo)
  // const settings = useSelector((state) => state.settings);
  const settings = loadSettingsFromLocalStorage()
  console.log("loadSettingsFromLocalStorage", settings)
  const navigate = useNavigate();
  const auto_login_id = getAutoLoginUserid()
  const [menuHeading, setMenu] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [Settings, setSettings] = useState({});
  const [uncheckedPhoto, setUncheckedPhoto] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { profile } = useLoginUser();
  const loginRole = getRole();
  const textRef = useRef(null);
  const password = getPassword();
  const dispatch = useDispatch();

  console.log("settings->>>", settings)

  const checkLo = profile.lovedOneList.length > 0 && (
    profile.lovedOneList[0].relation === 'Self' || profile.lovedOneList[0].lo_id
  );

  console.log("PhoneSettings", settings)

  console.log("profile", profile)

  const toggleModal = (status) => () => {
    setIsModalOpen(status);
  };

  const confirmDisableManage = () => {
    handleCheck(selectedItem)({ target: { checked: false } });
    setIsModalOpen(false);
  };

  const handleError = useErrorHandler();
  const loId = profile.lovedOneList && profile.lovedOneList.length > 0
    ? profile.lovedOneList[0].id
    : lovedOne.id;

  useEffect(() => {
    if (password === "bigpurplephone@123") {
      setIsModalOpen(true);
      setModalType('changePassword');
    }
  }, [password]);
  useEffect(() => {
    setLoading(true);
    if (lovedOne && lovedOne.id) {
      getLOMenuEditable(loId)
        .then((result) => {
          setMenu(result.data.data);
          getUncheckedPhotoCount(lovedOne.id).then((res) =>
            setUncheckedPhoto(res.data.data)
          );
          const lovedOneIdToUse = lovedOne.lovedOneList && lovedOne.lovedOneList.length > 0
            ? lovedOne.lovedOneList[0].id
            : lovedOne.id;
          getSettings(loId)
            .then((data) => {
              setSettings(data);
              dispatch(updateSettingsSlice(
                {
                  lovedOneId: loId,
                  settings: { select_to_speak: data.select_to_speak, loved_one_id: loId },
                }
              ))
              setLoading(false);
            })
            .catch((error) => {
              return error.response.data.statusCode != 400
                ? handleError(error)
                : "";
            });
        })
        .catch((error) => {
          setMenu([]);
          console.log(error);
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [lovedOne && lovedOne.id]);

  const handleItemCheck = (item) => () => {
    console.log("item manage", item)
    if (item.name === "Manage my BigPurplePhone" && !item.hidden) {
      console.log("if block")
      setSelectedItem(item);
      setIsModalOpen(true);
      toggleConfirmationModal(true);
    } else {
      handleCheck(item)();
    }
  };

  const handleCheck = (item) => async (event) => {
    let data = {
      hidden: event.target.checked ? 0 : 1,
      id: item.id,
    };
    console.log("item->>>>>>>>>>>>>", settings.select_to_speak)
    speakText(event.target.checked ? `${item.name} Enable` : `${item.name} Disable`, true);

    if (item.name === "SOS") {
      handleSettings(event.target.checked);
    }
    editLovedOneMenu(data)
      .then((res) => {
        setShowModal(false);
        getLOMenuEditable(loId)
          .then((result) => {
            setMenu(result.data.data);
          })
          .catch((error) => {
            setMenu([]);
            console.log(error);
            return error.response.data.statusCode != 400
              ? handleError(error)
              : "";
          });
      })
      .catch((error) =>
        error.response.data.statusCode != 400 ? handleError(error) : ""
      )
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSettings = (value) => {
    const sosValue = value ? 1 : 0;

    console.log("settings data", settings)
    setSettings({ ...Settings, sos_button: value });
    updateSettings({ ...Settings, lovedOneId: loId, sos_button: sosValue })
      .then((data) => { })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };
  // if (isLoading) return <Spinner animation="border" variant="primary" />;
  if (isLoading) return (
    <div className="loader-container">
      <img src={loader} alt="Loading..." className="custom-loader" />
    </div>
  );


  const renderNameWithBoldPurple = (name) => {
    return name.split(/(Purple)/).map((part, index) =>
      part === "Purple" ? <strong key={index}>{part}</strong> : part
    );
  };

  const navigateToChangePassword = () => () => {
    navigate("/ChangePassword", { state: { type: "create" } });
  };

  // Function to toggle the confirmation modal
  const toggleConfirmationModal = (status) => {
    // console.log
    setIsModalOpen(status);
    setModalType(status ? 'confirmation' : null);
  };

  const updateCheckPhotos = () => {
    const payload = {
      lovedOneId:lovedOne.id
    }
    updateSharePhotos(payload)
      .then((data) => { })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  }

  return (
    <>

      {modalType === 'changePassword' && (
        <CustomModal
          isOpen={isModalOpen}
          toggleModal={() => navigateToChangePassword()}
          title="Change Your Password"
          description="For security reasons, we recommend changing your password after your first login."
          prompt={{
            cancel: "Change Password",
          }}
        />
      )}
      {modalType === 'confirmation' && (
        <CustomModal
          isOpen={isModalOpen}
          toggleModal={toggleModal}
          title="Confirmation"
          description="Are you really sure? This will remove the Manage icon from the BigPurplePhone so you cannot access this page from the BigPurplePhone home screen."
          prompt={{
            cancel: "Cancel",
            title: "Yes, Remove",
            handle: confirmDisableManage,
          }}
        />
      )}
      <div className="mainPage">
        <div className="Homeheaderbg">
          <div className="d-flex">
            {!auto_login_id && !checkLo && (
              <NavLink to="/LovedOneList">
                <img src={back_white} alt="Back"
                  onClick={() => speakText('Back', true)}
                />
              </NavLink>
            )}
            <div className="loginLogo">
              <img src={bigPurplePhoneLogo} className="w-100" />
            </div>
          </div>
          <div className="customer-info">
            <div className="col-md-6">
              <h3 className="m-0">Manage</h3>
              <h1>{lovedOne?.first_name}'s</h1>
              <h3>
                Big<b>Purple</b>Phone
              </h3>
            </div>
            <div className="pe-3">
              <div className="imgspace">
                <img
                  alt=""
                  src={lovedOne?.photo ? lovedOne.photo : default_image}
                  className="imgbig"
                />

                <img
                  src={editCircle}
                  className="imgEditSmall"
                  alt=""
                  onClick={() => {
                    speakText("Edit Profile", true)
                    navigate("/LOEditProfile", {
                      state: { ...lovedOne, backpage: "/MenuScreen" },
                    })
                  }
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>
        <div className="contentbodymedia manageapps">
          <div className="contentBody">
            <h5 className="menuHeaderText">
              Here you can make video calls with {lovedOne?.first_name} and
              their friends and family, add contacts and set up{" "}
              {lovedOne?.first_name}'s Big<b>Purple</b>Phone just the way
              they like it! You can also disable features, set reminders,
              share photos and more. Scroll down for options.
            </h5>
            <div>
              {Object.keys(menuHeading).map((section, index) => (
                <div key={index}>

                  <h1 className="sectionHeader">
                    {renderNameWithBoldPurple(section)}
                    {(section === 'Manage my BigPurplePhone' || section === 'Web Apps') && (
                      <span className="greentext ms-3">New!</span>
                    )}
                  </h1>
                  <div className="media-box-white mb-3">
                    <ul className="media-list">
                      {menuHeading[section].map((item) => (
                        <>
                          {
                            item.icon_name === 'web_apps' ? (
                              <>
                                <div className="d-flex align-items-end mb-2">
                                  <a className="">
                                    <img className="webappimg-w " src={ImageComponent[item.icon_name]} title={item.name} />
                                  </a>
                                  <a href={item.slug} onClick={() => speakText('Web Apps', true)}
                                    className="light-btn ms-auto">Choose</a>
                                </div>

                                <div className="col text-grey1">
                                  <h5 className="fw_medium fw-20 mb-1">Web Apps</h5>
                                  <p className="subTitleBlog pt-0">Select up to 5 Apps you would like available on {lovedOne?.first_name}’s Big<b>Purple</b>Phone</p>
                                </div>
                              </>
                            ) : item.name == 'Manage my BigPurplePhone' ? (
                              <>
                                <li key={item.id}>
                                  <div className="media-list-icon-col">
                                    <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                  </div>
                                  <div className="media-list-content-col">
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={item.id}
                                        onChange={!item.hidden ? handleItemCheck(item) : handleCheck(item)}
                                        checked={!item.hidden}
                                        disabled={item.primary === 1 || (item.name === "Games" && item.hidden === 1) || (item.name === "News" && item.hidden === 1)}
                                      />
                                      {item.name !== 'Settings' && item.name !== 'Manage' && (
                                        <label className="form-check-label" htmlFor={item.id}></label>
                                      )}
                                    </div>
                                  </div>
                                  <div className="text-grey1 w-100 mt-2">
                                    <h5 className="fw_medium fw-20 mb-1">{renderNameWithBoldPurple(item.name)}</h5>
                                    <p className="subTitleBlog pt-0">You can add an icon to your Big<b>Purple</b>Phone so {lovedOne?.first_name} can access this page from their Big<b>Purple</b>Phone</p>
                                  </div>
                                </li>
                              </>
                            ) : item.name == 'Wifi' || item.name == 'Bluetooth' ? (
                              <>
                                <li key={item.id}>
                                  <div className="media-list-img-col">
                                    <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                  </div>

                                  <div className="media-list-content-col">
                                    <span className="greentext fw_medium fw-20">New!</span>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={item.id}
                                        onChange={handleCheck(item)}
                                        checked={!item.hidden}
                                      />
                                      {item.name !== 'Settings' && item.name !== 'Manage' && (
                                        <label className="form-check-label" htmlFor={item.id}></label>
                                      )}
                                    </div>
                                  </div>

                                </li>
                              </>
                            ) : item.name == 'Find My Phone' ? (
                              <>
                                <li key={item.id}>
                                  <div className="media-list-icon-col">
                                    <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                  </div>

                                  <div className="media-list-content-col">
                                    <span className="greentext fw_medium fw-20">New!</span>
                                    <a className="light-btn" href={`${item.slug}?lovedOneId=${lovedOne?.id}`} onClick={() => speakText(item.name, true)}
                                    >
                                      {item.name}
                                    </a>
                                  </div>

                                </li>
                              </>
                            ) : item.name == 'Billing' ? (
                              <>
                                <li key={item.id}>

                                  <div className="media-list-img-col">
                                    <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                  </div>

                                  <div className="media-list-content-col">
                                    <a className="light-btn ms-auto" href={item.slug}>
                                      {item.name}
                                    </a>
                                  </div>

                                </li>
                              </>
                            ) : item.name == 'Phone' ? (
                              <>
                                <li key={item.id}>
                                  <div className="media-list-icon-col">
                                    <a className=""
                                    >
                                      <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                    </a>
                                  </div>
                                  <div className="media-list-content-col">
                                    <NavLink
                                      ref={textRef}
                                      to={item.slug}
                                      className={`light-btn`}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => speakText(item.name, true)}
                                    >
                                      Settings
                                    </NavLink>
                                  </div>

                                </li>
                              </>
                            ) : item.name == 'Make a Video Call' ? (
                              <>
                                <li key={item.id}>
                                  <div className="media-list-icon-col">
                                    <a className=""
                                    >
                                      <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                    </a>
                                  </div>

                                  <div className="media-list-content-col">
                                    {!auto_login_id && (
                                      <NavLink
                                        to={item.slug}
                                        className={`light-btn`}
                                        style={{ cursor: "pointer" }}
                                        ref={textRef}
                                        onClick={() => speakText('Video Call', true)}
                                      >
                                        Video Call
                                      </NavLink>
                                    )}
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={item.id}
                                        onChange={handleCheck(item)}
                                        checked={!item.hidden}
                                        disabled={item.primary === 1 || (item.name === "Games" && item.hidden === 1) || (item.name === "News" && item.hidden === 1)}
                                      />
                                      <label className="form-check-label" htmlFor={item.id}></label>
                                    </div>
                                  </div>
                                </li>
                              </>
                            ) : item.name == 'Camera' || item.name == 'Weather' || item.name == 'Help' ? (
                              <>
                                <li key={item.id}>
                                  <div className="media-list-icon-col">
                                    <a className=""
                                    >
                                      <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                    </a>
                                  </div>
                                  <div className="media-list-content-col">
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={item.id}
                                        onChange={handleCheck(item)}
                                        checked={!item.hidden}
                                        disabled={item.primary === 1 || (item.name === "Games" && item.hidden === 1) || (item.name === "News" && item.hidden === 1)}
                                      />
                                      <label className="form-check-label" htmlFor={item.id}></label>
                                    </div>
                                  </div>
                                </li>
                              </>
                            ) : item.name == 'Messages' ? (
                              <>
                                <li key={item.id}>
                                  <div className="media-list-icon-col">
                                    <a className=""
                                    >
                                      <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                    </a>
                                  </div>
                                </li>
                              </>
                            ) : item.name == 'Contacts' ? (
                              <>
                                <li key={item.id}>
                                  <div className="media-list-icon-col">
                                    <a className=""
                                    >
                                      <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                    </a>
                                  </div>
                                  <div className="media-list-content-col">
                                    <NavLink
                                      to={item.slug}
                                      className={`light-btn`}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => speakText(item.name, true)}

                                    >
                                      Manage
                                    </NavLink>
                                  </div>
                                </li>
                              </>
                            ) : item.name == 'Photos' ? (
                              <>
                                <li key={item.id}>
                                  <div className="media-list-icon-col">
                                    <a className=""
                                    >
                                      <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                    </a>
                                  </div>
                                  <div className="media-list-content-col">
                                    {!auto_login_id && (
                                      <NavLink
                                        to={item.slug}
                                        className={`light-btn`}
                                        style={{ cursor: "pointer" }}
                                        // onClick={() => speakText('Share Photos', true)}
                                        onClick={updateCheckPhotos}
                                      >
                                        Share Photos
                                      </NavLink>
                                    )}
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={item.id}
                                        onChange={handleCheck(item)}
                                        checked={!item.hidden}
                                        disabled={item.primary === 1 || (item.name === "Games" && item.hidden === 1) || (item.name === "News" && item.hidden === 1)}
                                      />
                                      <label className="form-check-label" htmlFor={item.id}></label>
                                    </div>
                                  </div>
                                </li>
                              </>
                            ) : item.name == 'Radio' || item.name == 'Music' ? (
                              <>
                                <li key={item.id}>
                                  <div className="media-list-icon-col">
                                    <a className=""
                                    >
                                      <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                    </a>
                                  </div>
                                  <div className="media-list-content-col">
                                    <NavLink
                                      to={item.slug}
                                      className={`light-btn`}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => speakText(item.name, true)}
                                    >
                                      Choose
                                    </NavLink>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={item.id}
                                        onChange={handleCheck(item)}
                                        checked={!item.hidden}
                                        disabled={item.primary === 1 || (item.name === "Games" && item.hidden === 1) || (item.name === "News" && item.hidden === 1)}
                                      />
                                      <label className="form-check-label" htmlFor={item.id}></label>
                                    </div>
                                  </div>
                                </li>
                              </>
                            ) : item.name == 'Vaccination Certificate' ? (
                              <>
                                <li key={item.id}>

                                  <div className="media-list-icon-col">
                                    <a className=""
                                    >
                                      <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                    </a>

                                  </div>
                                  <div className="media-list-content-col">
                                    {!auto_login_id && (
                                      <NavLink
                                        to={item.slug}
                                        className={`light-btn`}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => speakText(item.name, true)}
                                      >
                                        Upload
                                      </NavLink>
                                    )}
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={item.id}
                                        onChange={handleCheck(item)}
                                        checked={!item.hidden}
                                      />
                                      <label className="form-check-label" htmlFor={item.id}></label>
                                    </div>
                                  </div>
                                </li>
                              </>

                            ) : item.name == 'SOS' ? (
                              <>
                                <li key={item.id}>

                                  <div className="media-list-icon-col">
                                    <a className=""
                                    >
                                      <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                    </a>
                                  </div>
                                  <div className="media-list-content-col">
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={item.id}
                                        onChange={handleCheck(item)}
                                        checked={!item.hidden}
                                        disabled={item.primary === 1 || (item.name === "Games" && item.hidden === 1) || (item.name === "News" && item.hidden === 1)}
                                      />
                                      <label className="form-check-label" htmlFor={item.id}></label>
                                    </div>
                                  </div>
                                </li>
                              </>
                            ) : item.name == 'Birthdays & Anniversaries' || item.name == 'Reminders' ? (
                              <>
                                <li key={item.id}>

                                  <div className="media-list-icon-col">

                                    <a className=""
                                    >
                                      <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                    </a>

                                  </div>
                                  <div className="media-list-content-col">
                                    <NavLink
                                      to={item.slug}
                                      className={`light-btn`}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => speakText(item.name, true)}

                                    >
                                      Manage
                                    </NavLink>
                                    {/* {item.name == 'Birthdays & Anniversaries' && (
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id={item.id}
                                          onChange={handleCheck(item)}
                                          checked={!item.hidden}
                                          disabled={item.primary === 1 || (item.name === "Games" && item.hidden === 1) || (item.name === "News" && item.hidden === 1)}
                                        />
                                        <label className="form-check-label" htmlFor={item.id}></label>
                                      </div>)} */}

                                  </div>

                                </li>
                              </>
                            ) : item.name == 'Support' ? (
                              <>
                                <li key={item.id}>

                                  <div className="media-list-img-col">
                                    <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                  </div>
                                  <div className="text-grey1 ms-3 col">
                                    <h5 className="fw_medium fw-20 mb-1">{item.name}</h5>
                                    <p className="subTitleBlog pt-0 support-text">{item.text}</p>
                                  </div>

                                </li>
                              </>
                            ) : (
                              <li key={item.id}>

                                <div className="media-list-icon-col">
                                  <a className="media-list-img-col">
                                    <img className="media-list-img " src={ImageComponent[item.icon_name]} title={item.name} />
                                  </a>

                                </div>
                                <div className="media-list-content-col">

                                  {item.name.trim() !== '' && (
                                    <NavLink
                                      to={item.slug}
                                      className={` ${item.icon_name === 'internet' ? '' : 'light-btn'}`}

                                      style={{ cursor: "pointer" }}
                                    >
                                      {item.icon_name == "internet" || item.icon_name == 'wifi' || item.icon_name == 'bluetooth' ? (
                                        <span className="greentext fw_medium fw-20 ms-3">New!</span>
                                      ) : (
                                        item.name
                                      )}
                                    </NavLink>
                                  )}

                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={item.id}
                                      onChange={handleCheck(item)}
                                      checked={!item.hidden}
                                      disabled={item.primary === 1 || (item.name === "Games" && item.hidden === 1) || (item.name === "News" && item.hidden === 1)}
                                    />
                                    {item.name !== 'Settings' && item.name !== 'Manage' && (
                                      <label className="form-check-label" htmlFor={item.id}></label>
                                    )}
                                  </div>
                                </div>
                              </li>
                            )
                          }
                        </>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div >
    </>
  );
}
